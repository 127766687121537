<template>
	<nav>
		<!-- App Bar -->
		<v-app-bar app clipped-left dark class="primary text-center">
			<!-- <v-app-bar-nav-icon @click.stop="toggleMini" /> -->
			<span class="title appbar-title ml-3 mr-5">
				<span class="font-weight-light">Petnet</span>&nbsp;<span>ERP</span>
			</span>
			<v-text-field
        flat
        solo-inverted
        hide-details
        prepend-inner-icon="search"
        label="Search for Batch Number then press the 'Enter' key"
        class="hidden-sm-and-down"
				v-show="$route.name!=='search-transaction'"
				@keyup="searchBatchNumber"
      ></v-text-field>
			<v-spacer />
			<v-menu dark offset-y transition="slide-y-transition">
				<template v-slot:activator="{ on }">
					<v-btn text outlined color="white" dark large v-show="isAuthenticated" v-on="on">
						<v-avatar size="32px" item>
							<v-img :src="getProfile()"/>
						</v-avatar>
						&nbsp;&nbsp;
						<span>{{ currUser.user_first_name }} {{ currUser.user_last_name }}</span>
					</v-btn>
				</template>
				<v-list >
					<v-list-item @click="logout">
						<v-list-item-title>Sign Out</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
		</v-app-bar>

		<!-- Drawer -->
		<v-navigation-drawer id="app-drawer" v-model="drawer" app
		clipped class="drawer-style"  dark color="grey darken-2"
		:mini-variant.sync="mini" permanent :src="image"
		mobile-breakpoint="991"
		expand-on-hover
		>
			<template v-slot:img="attrs">
				<v-img
					v-bind="attrs"
					gradient="to top, rgba(0, 0, 0, .7), rgba(0, 0, 0, .7)"
				/>
			</template>
			<v-list dense class="pt-3 white--text">
				<v-list-item router to="/" active-class="primary white--text">
					<v-list-item-icon>
						<v-icon>dashboard</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Dashboard</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-item href="https://docs.google.com/document/d/1PN1pdxzImAihQZOen6-gSZ_7jIFhaupr3iTjPykR29s/"  target="_blank"  active-class="primary white--text">
					<v-list-item-icon>
						<v-icon>mdi-help-circle-outline</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>User Manual</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-list-group prepend-icon="fa-folder" v-if="links.length > 0">
					<v-list-item-title slot="activator">
							<div>File Maintenance</div>
					</v-list-item-title>
					<v-list-item
						v-for="link in links"
						:key="link.text"
						router
						:to="link.route"
						active-class="primary white--text"
					>
						<v-list-item-icon>
							<v-icon small>{{ link.icon }}</v-icon>
						</v-list-item-icon>

						<v-list-item-content>
							<v-list-item-title>{{ link.text }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-group>
				<v-list-group prepend-icon="mdi-bank-transfer" v-if="trx_links.length > 0">
					<v-list-item-title slot="activator">
						<div>Transactions</div>
					</v-list-item-title>
					<v-list-item
							v-for="link in trx_links"
							:key="link.text"
							router
							:to="link.route"
							active-class="primary white--text"
					>
						<v-list-item-icon>
							<v-icon small>{{ link.icon }}</v-icon>
						</v-list-item-icon>

						<v-list-item-content>
							<v-list-item-title>{{ link.text }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-group>
				<v-list-group prepend-icon="fa-copy" v-if="rpt_links.length > 0">
					<v-list-item-title slot="activator">
						<div>Reports</div>
					</v-list-item-title>
					<v-list-item
						v-for="link in rpt_links"
						:key="link.text"
						router
						:to="link.route"
						active-class="primary white--text"
					>
						<v-list-item-icon>
							<v-icon small>{{ link.icon }}</v-icon>
						</v-list-item-icon>

						<v-list-item-content>
							<v-list-item-title>{{ link.text }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-list-group>
			</v-list>
		</v-navigation-drawer>
	</nav>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'; 
// import io from 'socket.io-client';

export default {
	data() {
		return{
			image: 'https://demos.creative-tim.com/vue-material-dashboard/img/sidebar-2.32103624.jpg',
			drawer: false,
			mini: false,
			links: [],
			rpt_links: [],
			trx_links: [],
			searchValue: '',
			links_data: [
				{ icon: 'fa-calendar-alt', text: 'Accounting Period', route: '/acctperiodmain'},
				{ icon: 'fa-list', text: 'Acknowledgement Receipt', route: '/acknowledgement_receipt'},
				{ icon: 'fa-user-check', text: 'Alphanumeric Tax Code', route: '/alphataxcode'},
				{ icon: 'fa-user-check', text: 'Approving Levels', route: '/approvinglevels'},
				{ icon: 'fa-user-check', text: 'Approving Officers', route: '/approvingofficers'},
				{ icon: 'fa-university', text: 'Banks', route: '/banks'},
				{ icon: 'fa-briefcase', text: 'Bank Headers', route: '/bankheader'},
				{ icon: 'fa-university', text: 'BP Banks', route: '/bpbanks'},
				{ icon: 'fa-map-marked', text: 'BP Locations', route: '/bplocations'},
				{ icon: 'fa-network-wired', text: 'Branches', route: '/branches'},
				{ icon: 'fa-handshake', text: 'Business Partners', route: '/businesspartners'},
				{ icon: 'fa-chart-bar', text: 'Chart of Account', route: '/coa'},
				{ icon: 'fa-building', text: 'Company', route: '/companies'},
				{ icon: 'fa-coins', text: 'Currencies', route: '/currency'},
				{ icon: 'fa-hotel', text: 'Departments', route: '/depts'},
				{ icon: 'fa-donate', text: 'Currency Rates', route: '/dollarrate'},
				{ icon: 'fa-book', text: 'Modules', route: '/module'},
				{ icon: 'fa-receipt', text: 'Official Receipt', route: '/official_reciept'},
				{ icon: 'fa-user-friends', text: 'Payee', route: '/payefile'},
				{ icon: 'fa-chalkboard-teacher', text: 'Regional Head', route: '/regionalhead'},
				{ icon: 'fa-file-alt', text: 'Regular Transactions', route: '/regulartxns'},
				// { icon: 'fa-user-tag', text: 'Roles', route: '/roles'},
				{ icon: 'fa-universal-access', text: 'Role Access', route: '/roleaccess'}, 
				{ icon: 'fa-toolbox', text: 'Billing Services', route: '/services'}, 
				{ icon: 'fa-file', text: 'Transaction Type', route: '/transaction'},
				{ icon: 'fa-user', text: 'Users', route: '/users'},
				{ icon: 'fa-bars', text: 'Voucher Series', route: '/voucher'},
				{ icon: 'fa-receipt', text: 'Billing Invoice', route: '/billing-invoice'},
				{ icon: 'fa-user-check', text: 'Billing Officers', route: '/billing-officers'},
				
				// { icon: 'fa-hotel', text: 'Sections', route: '/sections'},    
				// { icon: 'folder', text: 'My Projects', route: '/projects'},
				// { icon: 'person', text: 'Team', route: '/team'}
			],
			trx_links_data: [
				{ icon: 'fa-book-open', text: 'Active Transactions', route: '/trx-active'},
				{ icon: 'fa-archive', text: 'Archived Transactions', route: '/trx-archive'},
				{ icon: 'fa-money-check', text: 'Billing', route: '/trx-billing'},
				{ icon: 'fa-file-invoice', text: 'Cash Receipts', route: '/trxcr'},
				{ icon: 'fa-newspaper', text: 'Disbursement', route: '/trxdisb'},
				{ icon: 'fa-book', text: 'Journal Entries', route: '/trx-gen-journal'},
				{ icon: 'fa-file-upload', text: 'Upload from IS', route: '/trx-upload-is'},
				{ icon: 'fa-money-check', text: 'Collection', route: '/trx-collection'},
				{ icon: 'fa-store-alt', text: 'Purchase Order', route: '/trx-purchase-order'},
				{ icon: 'fa-credit-card', text: 'Payment', route: '/trx-payment'},
				{ icon: 'fa-credit-card', text: 'Integrated Transactions', route: '/trx-integrated'},
			],
			rpt_links_data: [
				{ icon: 'fa-file-alt', text: 'Profitability', route: '/profitability'},
				{ icon: 'fa-file-alt', text: 'Subsidiary', route: '/subsidiary'},
				{ icon: 'fa-file-invoice', text: 'Subsidiary Analysis', route: '/subsidiary-analysis'},
				{ icon: 'fa-chart-bar', text: 'Trial Balance', route: '/trial-balance'},
				{ icon: 'fa-chart-bar', text: 'AR Aging', route: '/ar-aging'},
				{ icon: 'fa-file', text: 'Billing List', route: '/billing-list'},
				{ icon: 'fa-file-alt', text: 'CV CIB Subsidiary Schedule', route: '/cibSubsidiary'},
				{ icon: 'fa-file-alt', text: 'RFA', route: '/rfa'},
				{ icon: 'fa-file-alt', text: 'Books of Account', route: '/boa'},
				{ icon: 'fa-file-alt', text: 'Audit Trail', route: '/auditTrail'},
				{ icon: 'fa-file-alt', text: 'Sales Journal', route: '/salesJournal'},
				// { icon: 'fa-network-wired', text: 'General Ledger', route: '/branches'},
				// { icon: 'fa-hotel', text: 'Balance Sheet', route: '/depts'},
			]
		};
	},
	computed: {
		...mapGetters({
			currUser: 'auth/currUser',
			roleaccess: 'auth/roleaccess',
			isAuthenticated: 'auth/isAuthenticated',
			userLoggedIn: 'auth/getUser',
		}),
	},
	created()
	{
		let roles = {};
		roles = this.currUser.roleaccess;

		if(roles.length > 0){
			this.links = [];
			this.rpt_links = [];
			this.trx_links = [];
			roles.sort((a,b) => (a.module_name > b.module_name) ? 1 : ((b.module_name > a.module_name) ? -1 : 0));
			roles.map(det => {
				if(det.module_type === 'F'){
					let links = this.links_data.filter(data => (data['text'] === det.module_name));
					if(links.length > 0){
						this.links.push(links[0]);
					}
				} else if(det.module_type === 'R'){
					let rpt_links = this.rpt_links_data.filter(data => (data['text'] === det.module_name));
					if(rpt_links.length > 0){
						this.rpt_links.push(rpt_links[0]);
					}
				} else if(det.module_type === 'T'){
					let trx_links = this.trx_links_data.filter(data => (data['text'] === det.module_name));
					if(trx_links.length > 0){
						this.trx_links.push(trx_links[0]);
					}
				}
			});
		}

		// var app = this;

		// app.$echo.channel(process.env.VUE_APP_SOCKET_EVENT_ENDPOINT)
		// 	.listen('.SendUpdatedDisbursementTxnsEvent', (payload) => {
		// 		delete payload.socket;

		// 		setTimeout(function() {
		// 			app.$store.commit('trxdisb/TRXDISBS_UPDATED', payload);
		// 		}, 2000);
		// 	});
	},
	methods: {
		...mapActions({
			doLogout: 'auth/doLogout',
			getCurrentUser: 'auth/getCurrentUser',
		}),
		toggleMini() {
			return this.mini = !this.mini;
		},
		logout(){
			this.$store.dispatch('auth/removeStorage');
			// this.doLogout();
			// this.$router.push('/',{});
		},
		routeTo (pRouteTo) {
			if (this.breadcrumbList[pRouteTo].link) this.$router.push(this.breadcrumbList[pRouteTo].link);
		},
		searchBatchNumber (e) {

			if (e.keyCode === 13) {
				let value = e.target.value;
				localStorage.setItem('searched_item', value);
				this.$router.push({ name: 'search-transaction', params: {number: value }});
			}
		},
		getLinks () {
			let links = [];
			let roles = {};
			roles = this.currUser.roleaccess;

			if(roles.length > 0){
				this.links = [];
				this.rpt_links = [];
				this.trx_links = [];
				roles.sort((a,b) => (a.module_name > b.module_name) ? 1 : ((b.module_name > a.module_name) ? -1 : 0));
				roles.map(det => {
					if(det.module_type === 'F'){
						let link = this.links_data.filter(data => (data['text'] === det.module_name));
						if(link.length > 0){
							links.push(link[0]);
						}
					}
				});
			}

			return links;
		},
		getTrxLinks () {
			let links = [];
			let roles = {};
			roles = this.currUser.roleaccess;

			if(roles.length > 0){
				this.links = [];
				this.rpt_links = [];
				this.trx_links = [];
				roles.sort((a,b) => (a.module_name > b.module_name) ? 1 : ((b.module_name > a.module_name) ? -1 : 0));
				roles.map(det => {
					if(det.module_type === 'T'){
						let link = this.trx_links_data.filter(data => (data['text'] === det.module_name));
						if(link.length > 0){
							links.push(link[0]);
						}
					}
				});
			}

			return links;
		},
		getProfile() {
			let pic = 'https://randomuser.me/api/portraits/men/85.jpg';
			const data = JSON.parse(localStorage.getItem('auth-data'));
			if(data['google']){
				pic = data['google']['profile_picture'];
			}
			return pic;
		},
		getRptLinks () {
			let links = [];
			let roles = {};
			roles = this.currUser.roleaccess;

			if(roles.length > 0){
				this.links = [];
				this.rpt_links = [];
				this.trx_links = [];
				roles.sort((a,b) => (a.module_name > b.module_name) ? 1 : ((b.module_name > a.module_name) ? -1 : 0));
				roles.map(det => {
					if(det.module_type === 'R'){
						let link = this.rpt_links_data.filter(data => (data['text'] === det.module_name));
						if(link.length > 0){
							links.push(link[0]);
						}
					}
				});
			}

			return links;
		}
	},
};
</script>

<style scoped>
	.appbar-title {
		width: 180px;
		text-align: left;
	}
</style>