<template>
  <div>
    <div id="printAction" class="action">
      <v-row >
        <v-spacer class="hidden-sm-and-down"></v-spacer>
        <v-col cols="10">
          <h4>Report Name: Sales Journal</h4>
        </v-col>
        <v-col cols="2">
           <v-btn color="success" @click="exportExcel('printArea', 'Sales Journal')"><v-icon left>mdi-microsoft-excel</v-icon> Export</v-btn>
        </v-col>
      </v-row>
    </div>
    <div id="printArea" ref="content" style="width: 90%; font-size: 12px; margin: auto;">
		<v-row>
			<v-col cols="9">
			<p>
				<b>PETNET INC.</b>
				<br>
				<b>Sales Journal</b>
				<br>
				<b>{{getDateFormat(detail.date_from)}} TO {{getDateFormat(detail.date_to)}}</b>
			</p>
			</v-col>
		</v-row>
		<div id="tableDiv">
			<table id="table" style="width: 95%; margin: auto;" ref="table">
				<tr>
					<th>Date</th>
					<th>Customer No.</th>
					<th>Customer Name</th>
					<th>Address</th>
					<th>OR/OAR Number</th>
					<th>Description</th>
					<th>Amount</th>
					<th>Vat</th>
					<th>Net Service Fee</th>
				</tr>
				<tr style="outline: 1px solid black; width: 100%; margin: 10px;">
				</tr>
				<tr v-for="(det, index) in salesData" :key="index">
					<td>{{det.receipt_date}}</td>
					<td>{{det.customer_tin_no}}</td>
					<td>{{det.customer_name}}</td>
					<td class="description">{{det.customer_address}}</td>
					<td>{{det.or_oar_number}}</td>
					<td>{{det.or_oar_service}}</td>
					<td style="text-align: right">{{getAmountFormat(det.or_oar_principal_amount)}}</td>
					<td style="text-align: right">{{getAmountFormat(det.or_oar_vat_amount)}}</td>
					<td style="text-align: right">{{getNet(det.or_oar_principal_amount, det.or_oar_vat_amount)}}</td>
				</tr>
			</table>
		</div>
	</div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import numeral from 'numeral'; 
import { format } from 'date-fns';
export default {
	data: () => ({
		detail: {},
		report_name: '',
		generated_by: '',
		generated_time: new Date().toLocaleTimeString(),
		generated_date: new Date().toLocaleDateString()

	}),
	mounted() {
		this.init(this.detail);
	},
	created() {
		var app = this;
		app.detail = JSON.parse(atob(app.$route.params.data)); 
		app.generated_by = app.detail.user;
	},
	computed: {
		...mapGetters({
			salesData: 'report/salesData',
		}),
	},
	methods:{
		print() {
			window.print();
		},
		exportExcel(tableID, filename = '') {
			const date = Math.floor(Date.now() / 1000);
			var downloadLink;
			var dataType = 'application/vnd.ms-excel';

			// var x = document.getElementsByClassName('amount');
			// var i;
			// for (i = 0; i < x.length; i++) {
			// 	x[i].innerHTML = '\'' + x[i].innerHTML;
			// }
			
			var x = document.getElementsByClassName('description');
			var i;
			for (i = 0; i < x.length; i++) {
				x[i].innerHTML = x[i].innerHTML.replace(/[`~!@#$%^&*()|+=?;:'"<>]/gi, ' ');
			}
			var tableSelect = document.getElementById(tableID);
			var tableHTML = tableSelect.outerHTML.replace(/ /g, '%20');


			filename = filename ? filename + date + '.xls' : 'excel_data.xls';
			downloadLink = document.createElement('a');
			document.body.appendChild(downloadLink);

			if (navigator.msSaveOrOpenBlob) {
				var blob = new Blob(['\ufeff', tableHTML], {
					type: dataType
				});
				navigator.msSaveOrOpenBlob(blob, filename);
			} else {
				downloadLink.href = 'data:' + dataType + ', ' + tableHTML;
				downloadLink.download = filename;
				downloadLink.click();
			}
			
			// for (i = 0; i < x.length; i++) {
			// 	x[i].innerHTML = x[i].innerHTML.replace('\'', '');
			// }
		},
		getDate(date){
			if(date){
				let newDate = new Date(date);
				return newDate.toLocaleDateString();
			} else {
				return date;
			}
			// return date;
		},
		getTime(time){
			if(time){
				let newTime = new Date(time);
				return newTime.toLocaleTimeString();
			} else {
				return time;
			}
		},
		getAmountFormat(amount){
			return numeral(amount).format('0,0.00');
		},
		getNet(principal, vat){
			let amount = principal - vat;
			return numeral(amount).format('0,0.00');
		},
		getMonth(){
			var month = new Array();
			month[1] = 'January';
			month[2] = 'February';
			month[3] = 'March';
			month[4] = 'April';
			month[5] = 'May';
			month[6] = 'June';
			month[7] = 'July';
			month[8] = 'August';
			month[9] = 'September';
			month[10] = 'October';
			month[11] = 'November';
			month[12] = 'December';
			var n = month[this.detail.month];
			return n;
		},
		getDateFormat(date){
			var month = format(new Date(date), 'MMM');
			var day = format(new Date(date), 'dd');
			var year = format(new Date(date), 'yyyy');
			var d = month + ' ' + day +', '+ year;
			return d.toUpperCase();
		},
		init(data){
			delete data.user;
			this.$store.dispatch('report/getSalesJournal', data);
		}
	}
};
</script>
<style scoped>
.report-template {
  width: 768px;
  /* border: 1px solid black; */
  font-size: 12px;
  margin: auto;
}
.tbl {
  width: 95%;
  margin: auto;
}
.code {
  text-align: center;
}
.amount {
  text-align: right;
}
.header {
  outline: 1px solid black;
  width: 100%;
  margin: 10px;
}
.total {
  text-align: right;
  font-weight: bold;
}
.action {
  padding: 20px;
}
.petnet-logo {
    width: 60%;
}
.title {
  text-justify: center;
}
#tableDiv {
	overflow-y: auto;
}
	@media print {
		body #printAction { 
			display:none; 
		}
		body #printArea { 
			display:block; 
		}
	} 
</style>